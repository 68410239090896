import React, { useEffect, useState } from 'react';
import supabase from './supabaseClient';
import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [currentTable, setCurrentTable] = useState('doneTime');

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from(currentTable)
        .select('*');

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        // Сортируем данные по столбцу 'id'
        const sortedData = data.sort((a, b) => a.id - b.id);
        setData(sortedData);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Load Chatbot script
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js';
    script.onload = () => {
      window.Chatbot.init({
        chatflowid: 'b84969c9-8bcb-4b0a-a064-0b5e2835bd0a',
        apiHost: 'https://flowise.kaminsoft.ru',
      });
    };
    document.body.appendChild(script);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [currentTable]); // Add currentTable as a dependency

  const handleTableChange = (table) => {
    setCurrentTable(table);
  };

  return (
    <div className="App">
      <h1>Таблицы</h1>
      <div className="button-container">
        <button onClick={() => handleTableChange('doneTime')}>Таблица с расчетами</button>
        <button onClick={() => handleTableChange('TimeTask')}>Общая таблица</button>
      </div>
      <table>
        <thead>
          <tr>
            {data.length > 0 && Object.keys(data[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((value, colIndex) => (
                <td key={colIndex}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
